<template lang="pug">
  div

    CCard
      CCardBody
        CRow
          CCol(sm=1)
            img(:src="constructHostV2(item.source_id)", style="max-height:64px")
          CCol(sm=5)
            div
              h4(class="card-title mb-0") {{item.name}}
            div
              img(:src="'/img/services/' + item.service + '.ico'")
              span  {{category.path}}
          CCol(sm=6)
            div(class="text-right")
                a(:href="item.service == 1 ? 'https://www.wildberries.ru/catalog/'+item.source_id+'/detail.aspx' : 'https://www.ozon.ru/context/detail/id/'+item.source_id+'/'", target="blank", class="btn btn-info btn-sm") Перейти на маркетплейс

    CRow
      CCol
        CButton(:color="'info'", v-bind="period.button===0 ? { shape: 'square' } : { variant: 'outline' }", class="m-2", active, @click="setPeriod(0)") За всё время
        CButton(:color="'info'", v-bind="period.button===7 ? { shape: 'square' } : { variant: 'outline' }", class="m-2", active, @click="setPeriod(7)") 7 дней
        CButton(:color="'info'", v-bind="period.button===14 ? { shape: 'square' } : { variant: 'outline' }", class="m-2", active, @click="setPeriod(14)") 14 дней
        CButton(:color="'info'", v-bind="period.button===30 ? { shape: 'square' } : { variant: 'outline' }", class="m-2", active, @click="setPeriod(30)") 30 дней

    //CNav(variant="pills", style="margin-bottom:30px")
      //CNavItem(@click="setPeriod(7)", :to="{query:{day:7}}") 7 дней
      //CNavItem(@click="setPeriod(14)") 14 дней
      //CNavItem(@click="setPeriod(30)") 30 дней
      //CNavItem Выберите период

    CCard
      CCardBody
        // Верхняя стата
        CRow(style="margin-top: 15px; margin-left: auto")
          CCol(sm="4", lg="3")
            CWidgetSimple(header="Выручка" :text="format(Stats.oborot) + ' руб'")
          CCol(sm="4", lg="3")
            CWidgetSimple(header="Продаж" :text="format(Stats.sales)")
          CCol(sm="4", lg="3")
            CWidgetSimple(header="Средний чек" :text="(Stats.oborot/Stats.sales > 0 ? format(Stats.oborot/Stats.sales) : 0) + ' руб'")
          CCol(sm="4", lg="3")
            CWidgetSimple(header="Остатки" :text="format(item.quantity)")

    CCard
      CCardBody
        CRow(style="margin-top: 55px;")

          // График Продаж vs Цена vs Скидка
          CCol(sm="6", md="8")
            CCard
              CCardHeader
                h4 Динамика продаж
              CCardBody
                //CChartBar(:datasets="graphs.PriceSellsDataset.datasets", :options="graphs.opts")
                LineChart(ref="PriceSells", :chartData="graphs.PriceSellsDataset" )


          // Карточка товара
          CCol(sm="6", md="4")
            CCard
              CCardHeader
                h4 О товаре
              CCardBody.GoodInfoTable
                CRow
                  CCol
                    b SKU
                  CCol.value
                    a(target="blank", :href="'https://www.wildberries.ru/catalog/'+item.source_id+'/detail.aspx'") {{item.source_id}}
                CRow
                  CCol
                    b Категория
                  CCol.value(@click="$router.push({name: 'Category', params: {id: item.categories}})") {{category.name}}
                CRow
                  CCol
                    b Бренд
                  CCol.value(v-if="item.brandid", @click="$router.push({name: 'Brand', params: {id: item.brandid}})") {{brand.name}}
                CRow
                  CCol
                    b Продавец
                  CCol.value(v-if="item.sellerid", @click="$router.push({name: 'Seller', params: {id: item.sellerid}})") {{seller.name}}
                CRow
                  CCol
                    b Цена
                  CCol {{format(item.pricefull)}} руб
                CRow
                  CCol
                    b Скидка
                  CCol {{format(item.discount)}}
                CRow
                  CCol
                    b Цена продажи
                  CCol {{format(item.price)}} руб
                CRow
                  CCol
                    b Отзывы
                  CCol {{item.reviews}}
                CRow
                  CCol
                    b Оценка
                  CCol {{item.stars}}

    CCard
      CCardBody
        CRow(style="margin-top: 55px; display: flex")
          CCol(md="12")
            CCard
              CCardHeader
                h4 Состояние складов
              CCardBody
                //CChartBar(:datasets="graphs.PriceSellsDataset.datasets", :options="graphs.opts")
                LineChart(ref="OrdersQnt", :chartData="graphs.OrdersQntDataset")

    CCard
      CCardHeader
        h4 История товара
      CCardBody
        CRow
          CCol(md="12")
            CDataTable(:items="itemHistory", :fields="itemFields", :sorter="true", hover="hover", striped="striped", border="border", fixed="fixed")




</template>

<script>

import LineChart from '../../components/BarChart';

export default {
  name: 'Good',
  components: {LineChart},

  data: function () {
    let that = this;
    return {

      item: {
        Id: that.$route.params.id,
        orders: 0,
        price: 0,
        quantity: 0
      },

      Stats: {
        oborot: 0, // Точно подсчитанный оборот
        sales: 0 // Всего заказов
      },

      //itemHistory: [],
      itemFields: [
        {key: "dt", label: "Дата", sorter: true},
        {key: "rating", label: "Позиция в категории", sorter: true},
        {key: "price", label: "Цена", sorter: true},
        {key: "orders", label: "Всего продаж", sorter: true},
        {key: "quantity", label: "Остатков", sorter: true},
        {key: "reviews", label: "Отзывов", sorter: true},
      ],

      graphs: {
        PriceSellsDataset: {datasets: []},
        OrdersQntDataset: {datasets: []},
      },

      category: {},
      brand: {},
      seller: {},

      period: {
        from: 0,
        to: 0,
        button: 0
      },

      show: true,
      isCollapsed: true,

      opts: {
        canAdd: false,
        canEdit: false,
        canDelete: false,
        where: {
          categories: that.$route.params.id
        }
      }

    }
  },
  mounted() {
    this.update();
  },
  methods: {

    setPeriod: function (period, periodFrom, periodTo) {

      this.period.button=period;

      if(period !== 0) {
        let d = (new Date())
        d.setDate(d.getDate() - period); this.period.from = d.toISOString().replace('T', ' ');
        this.period.to = (new Date()).toISOString().replace('T', ' ');
      }
      else {
        //TODO: периодфром и ту
        this.period.from=0;
        this.period.to=0;
      }

      // Делаем запрос с новыми данными
      this.update();

    },

    update: function () {

      let that = this;

      // сперва грузим сам товар
      window.REST.get('goods', {id: this.$route.params.id}).then(res=>{
        if(res.data.length > 0) {
          that.item = res.data[0];
          that.$set(this.$route.meta,'label', that.item.name);
          that.$store.commit('routeThis', this);
        }
      }).then(()=>{

        // затем грузим категорию, бренд и селлера
        window.REST.get('categories', {sourceid: that.item.categories}).then(res=>{
          that.category = res.data[0];
        })
        window.REST.get('brands', {sourceid: that.item.brandid}).then(res=>{
          that.brand = res.data[0];
        })
        window.REST.get('sellers', {sourceid: that.item.sellerid}).then(res=>{
          that.seller = res.data[0];
        })
        // изменения позиций
        //window.REST.get('changes', {gid: that.item.id}, null, null, ["-dt"], 1, 10).then(res=>{
        //  that.itemHistory = res.data.map(r=>{delete r.id; delete r.service; delete r.gid; return r;});
        //})

        let periodsstr = '';
        if(that.period.to !== 0) periodsstr+='&periodTo='+that.period.to
        if(that.period.from !== 0) periodsstr+='&periodFrom='+that.period.from

        // ГРАФИКИ
        window.REST.get('goods/graphs?id='+that.item.source_id + periodsstr).then(res=>{

          that.graphs.Avg = res.data.Avg;
          that.Stats.oborot = res.data.Stats.oborot;
          that.Stats.sales = res.data.Stats.sales;

          that.itemHistory = res.data.GoodHistory.map(r=>{delete r.id; delete r.service; delete r.gid; return r;});
console.log(that.itemHistory);
          that.graphs.PriceSellsDataset.datasets = [
            {
              data: res.data.PriceSells.map(r=>{return {x: new Date(r.y,parseInt(r.m)-1,r.d), y: r.totalsoldlastday};}),
              backgroundColor: '#E55353',
              label: 'Продажи за день',
              yAxisID: 'A',
              type: 'line',
              fill: false,
              color: 'blue',
              borderColor: 'blue'
            },
            {
              data: res.data.PriceSells.map(r=>{return {x: new Date(r.y,parseInt(r.m)-1,r.d), y: r.baseprice};}),
              backgroundColor: '#CCC',
              label: 'Цена',
              yAxisID: 'B',
              type: 'bar',
            },
          ];

          that.graphs.OrdersQntDataset.datasets = [
            {
              data: res.data.PriceSells.map(r=>{return {x: new Date(r.y,parseInt(r.m)-1,r.d), y: r.totalsoldlastday};}),
              backgroundColor: '#E55353',
              label: 'Продажи за день',
              yAxisID: 'A',
              type: 'line',
              fill: false,
              color: 'blue',
              borderColor: 'blue'
            },
            {
              data: res.data.PriceSells.map(r=>{return {x: new Date(r.y,parseInt(r.m)-1,r.d), y: r.basequantity};}),
              backgroundColor: '#CCC',
              label: 'Остатки',
              yAxisID: 'B',
              type: 'bar',
            },
          ];



          that.$refs.PriceSells.rend();
          that.$refs.OrdersQnt.rend();

        })

      })

    },

    columnsupdated: function (cols) {

      cols.set('brand', {label: "Бренд", hasFilter: true, });
      cols.set('name', {label: "Название", hasFilter: true, });
      cols.set('rating', {label: "Позиция", hasFilter: true, });
      cols.set('color', {label: "Цвет", hasFilter: false, });
      cols.set('orders', {label: "Продаж", hasFilter: true, });
      cols.set('price', {label: "Цена", hasFilter: true, });
      cols.set('quantity', {label: "Остатки", hasFilter: true, });
      cols.set('reviews', {label: "Отзывы", hasFilter: true, });
      cols.set('stars', {label: "Оценка", hasFilter: true, });
      cols.set('sellername', {label: "Продавец", hasFilter: true, });
      cols.set('_totalsumm', {label: "Оборот", hasFilter: true, });


      // Ненужные колонки
      cols.delete('id');
      //cols.delete('service');
      cols.delete('sourceid');
      cols.delete('categories');
      cols.delete('brandid');
      cols.delete('pricefull');
      cols.delete('discount');
      cols.delete('firstreview');
      cols.delete('sellerid');
      cols.delete('imglink');
      cols.delete('changes');
      cols.delete('_avgpos');
      cols.delete('_avgprice');
      cols.delete('_stockdays');
      cols.delete('_saledays');
      cols.delete('_salesumm');
      cols.delete('_counteddays');

    },
    gotoItem(row) {
      this.$router.push({name: 'Good', params:{id: row.id}});
    },

    volHostV2: function (t) {
      return t >= 0 && t <= 143 ? "//basket-01.wb.ru/" : t >= 144 && t <= 287 ? "//basket-02.wb.ru/" : t >= 288 && t <= 431 ? "//basket-03.wb.ru/" : t >= 432 && t <= 719 ? "//basket-04.wb.ru/" : t >= 720 && t <= 1007 ? "//basket-05.wb.ru/" : t >= 1008 && t <= 1061 ? "//basket-06.wb.ru/" : t >= 1062 && t <= 1115 ? "//basket-07.wb.ru/" : t >= 1116 && t <= 1169 ? "//basket-08.wb.ru/" : t >= 1170 && t <= 1313 ? "//basket-09.wb.ru/" : "//basket-10.wb.ru/"
    },

    constructHostV2: function (t) {
      let e = parseInt(t, 10)
          , n = ~~(e / 1e5)
          , r = ~~(e / 1e3)
          , o = this.volHostV2(n);
      return "https:".concat(o, "vol").concat(n, "/part").concat(r, "/").concat(t, "/images/big/1.jpg")
    },



format(number) {
      if(number % 1 === 0) // int
        return (new Intl.NumberFormat('ru-RU').format(number)).toString();
      else // float
        return (new Intl.NumberFormat('ru-RU').format(parseFloat(number.toFixed(1)))).toString();
    }


  }
}
</script>

<style lang="scss">

.bar-chart-wrapper {
  height: 310px;
}

.GoodInfoTable {
  .row {
    border-bottom: #e8e8e8 solid 1px;
    padding: 2px 0px;
    .value {
      color: blue;
      cursor: pointer;
    }
    .value:hover {
      text-decoration: underline;
    }
  }
}

</style>